.aboutService {
  padding: 100px;
  background-color: #000;
}

.capsule {
  width: 70%;
  border: 1px solid;
  border-radius: 100px 100px;
  height: 150px;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  background-color: red;
  font-size: 18px;
  margin: 20px;
}

.capsule p {
  padding: 10px;
}

.capsuleInfo {
  width: 70%;
}

.serviceImage {
  height: 149px;
  display: block;
  border-radius: 0 100px 100px 0;
  opacity: 0.7;
  width: 300px;
}

.capsule:nth-child(even) {
  background-color: #fff;
  float: right;
  margin: 20px;
  border-radius: 100px 100px;
}

.serviceImage:nth-child(odd) {
  border-radius: 100px 0 0 100px;
}

/* mobile */

@media screen and (max-width: 780px) {
  .aboutService {
    padding: 100px 30px;
  }

  .capsule {
    width: 100%;
    height: 100px;
    font-size: 15px;
    margin: 0;
  }
  .capsule p {
    font-size: 8px;
    padding: 10px;
  }
  .capsuleInfo {
    width: 70%;
  }

  .serviceImage {
    width: 100px;
    opacity: 0.7;
    height: 99px;
  }

  .capsule:nth-child(even) {
    float: left;
  }
}

@media screen and (min-width: 780px) and (max-width: 1024px) {
  .aboutService {
    padding: 100px 30px;
  }

  .capsule {
    width: 80%;
    height: 130px;
    font-size: 18px;
    margin: 0;
  }
  .capsule p {
    font-size: 12px;
    padding: 10px;
  }
  .capsuleInfo {
    width: 70%;
  }

  .serviceImage {
    width: 150px;
    opacity: 0.7;
    height: 129px;
  }

  .capsule:nth-child(even) {
    float: right;
  }
}
