.brands {
  padding: 40px;
  background-color: #000;
}

.brands h2 {
  font-weight: bold;
  color: red;
  font-size: 35px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;
  text-align: center;
  margin: 20px 0;
}

.logoBrands img {
  width: 100px;
  height: 100px;
  background-color: #fff;
  padding: 10px;
  border-radius: 16px;
}

.collapse {
  margin: 100px 0;
  margin-bottom: 0;
  border: 1px solid #fff;
  border-radius: 16px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 3px;
  padding: 10px 0;
}

.Collapsible__trigger .is-closed {
  width: 100%;
  cursor: pointer !important;
}

.imageContainer {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;
  justify-content: center;
}

.imageContainer img {
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: #fff;
  padding: 5px;
  border-radius: 16px;
}

.slick-prev:before,
.slick-next:before {
  color: #eecc91 !important;
}

/* mobile */

@media screen and (max-width: 780px) {
  .imageContainer {
    grid-template-columns: repeat(3, 1fr);
  }

  .imageContainer img {
    width: 80px;
    height: 80px;
  }

  .brands h2 {
    font-size: 25px;
  }
}

/* tablet */

@media screen and (min-width: 780px) and (max-width: 1024px) {
  .imageContainer {
    grid-template-columns: repeat(7, 1fr);
  }

  .imageContainer img {
    width: 80px;
    height: 80px;
  }

  .brands h2 {
    font-size: 25px;
  }
}
