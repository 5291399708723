@import url("https://fonts.googleapis.com/css2?family=Tourney:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
.heroImage {
  height: 100vh;
  position: relative;
  background: rgba(0, 0, 0, 0.2)
    url("https://www.prologsupply.co.uk/tema/img/slider/1.jpg");
  background-size: cover;
  background-blend-mode: darken;
}

.slider img {
  height: 100vh;
  width: 100%;
}

.sliderCaption {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left {
  width: 50%;
  height: 500px;
}

.right {
  width: 50%;
}

.firstCaption {
  display: flex;
  align-items: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: 100;
  font-size: 1.3rem;
  margin-top: -20rem;
  letter-spacing: 0.3rem;
  color: #fff;
  padding-left: 4%;
  width: 80%;
}

.firstCaption h1 {
  width: 100%;
  padding-left: 6%;
}

.line {
  width: 100px;
  height: 4px;
  background: #f50000;
  float: right;
  /* padding-left: 4%; */
}

.secondCaption {
  font-size: 1.5rem;
  padding-left: 3%;
  letter-spacing: 5px;
  width: 100%;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.description {
  position: absolute;
  color: #fff;
  margin-top: -25rem;
  padding: 10px 30px;
  background: rgba(0, 0, 0, 0.5);
  margin-right: 60px;
  border-radius: 20px;
  letter-spacing: 1px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  font-family: "Times New Roman", Times, serif;
}

/* mobile */

@media only screen and (max-width: 780px) {
  .heroImage {
    background-position: left 15% bottom 45%;
  }
  .right,
  .left,
  .firstCaption {
    width: 100%;
  }
  .left {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .right {
    margin-top: -200rem;
    display: flex;
    justify-content: center;
    text-align: start;
  }

  .description {
    margin: 0 10px;
    top: -500px;
    font-size: 18px;
  }
  .firstCaption {
    font-size: 0.8rem;
    padding-right: 4%;
    margin-top: -40rem;
  }

  .firstCaption h1 {
    padding-left: 0;
  }

  .secondCaption {
    font-size: 0.8rem;
  }

  .sliderCaption {
    flex-direction: column;
    column-gap: 10px;
  }
}

/* tablet */

@media only screen and (min-width: 780px) and (max-width: 1024px) {
  .right,
  .left,
  .firstCaption {
    width: 100%;
  }
  .left {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .right {
    margin-top: -200rem;
    display: flex;
    justify-content: center;
    text-align: start;
  }

  .description {
    margin: 0 10px;
    top: -300px;
    font-size: 22px;
  }
  .firstCaption {
    font-size: 1rem;
    padding-right: 4%;
    margin-top: -30rem;
  }

  .firstCaption h1 {
    padding-left: 0;
  }

  .secondCaption {
    font-size: 0.8rem;
  }

  .sliderCaption {
    flex-direction: column;
    column-gap: 10px;
  }
}
